import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ModalController, PopoverController, AlertController, AnimationController, ToastController  } from '@ionic/angular';

import { BrainstormingService } from '@app/-core/brainstorm-ai/brainstorming.service';

declare var ResizeObserver;

import * as hexToFilter from 'css-filter-generator';

@Component({
  selector: 'element',
  templateUrl: './editor-element.component.html',
  styleUrls: ['./editor-element.component.scss'],
})
export class EditorElementComponent implements OnInit, OnDestroy {

  // @Input() parentRef: any = null;
  resizeInterval;

  @Input() type = 'text';
  @Input() attr: any = {};
  @Input() value: any = 'None';
  @Input() key = null;
  @Input() options: any = [];

  @Input() preview = false; // non interactive mode
  @Input() imageOnly = false; // hack to async load images

  @Input() meta: any = null;
  @Input() metaIndex: any = null;

  focusSwitch = false; // setFocus / text-container

  @Output('tinyInitiated') tinyInitiated = new EventEmitter<object>();

  usesXY: any = false;
  usesWH: any = false;
  usesSwapable: any = false;

  resizable = true; //
  resizing = false;
  mousedown = false;
  observer;

  @Input() action;
  actionLock = false;

  @Input() ready: any = true;

  @Output() getValueChange = new EventEmitter<object>();
  @Output() getPositionChange = new EventEmitter<object>();

  @ViewChild('chatMCE') chatMCE: any;
  @ViewChild('textBlock') textBlock: any;

  @ViewChild('resizeBox', {static: false}) resizeBox: any;
  @ViewChild('inPreview', {static: false}) inPreview: any;

  debounce = null;
  debounceValue;

  hf = hexToFilter;

  rtCahche;
  rtCahcheFilter;

  viewEdit = false;

  saveAreaScaleValue: any = 1;
  // scale = 0.5;

  constructor(
    private host: ElementRef, 
    public alertController: AlertController, 
    public toastController: ToastController,
    public brainstorming: BrainstormingService,
  
  ) {
  }

  numberToHexOpacity(val) {
    if ( val !== undefined ) {
      let subj =  parseInt( '' + parseInt(val, 10)*2.55, 10);
      if ( val <= 5 ) {
        return '00';
      }
      return (subj).toString(16);
    } else {
      return 'FF';
    }
  }


  // @HostListener('document:mousemove', ['$event'])
  onMouseMove = (ev) => {
    if ( this.resizing === true ) {
      const rect = this.resizeBox.nativeElement.getBoundingClientRect();
      if ( this.usesWH.w >= 32 ) {
        this.usesWH.w = - ( ( (rect.x / this.saveAreaScaleValue) - ev.pageX / this.saveAreaScaleValue ) ) + 22 ;
        this.usesWH.w = Math.round(this.usesWH.w);
      } else {
        this.usesWH.w = 48;
        this.resizing = false;
      }
      if ( this.usesWH.h >= 12 ) {
        this.usesWH.h = - ( ( (rect.y / this.saveAreaScaleValue) - ev.pageY / this.saveAreaScaleValue ) ) + 22 ;
        this.usesWH.h = Math.round(this.usesWH.h);
      } else {
        this.usesWH.h = 22;
        this.resizing = false;
      }
    }
  }

  // @HostListener('document:mouseup', ['$event'])
  onMouseUp = (ev) => {
    if ( this.resizing === true ) {
      this.getPositionChange.emit({action: 'resize'});
    }
    this.resizing = false;
    (window as any).document.removeEventListener('mousemove', this.onMouseMove);
    (window as any).document.removeEventListener('mouseup', this.onMouseUp);
    //
  }

  init() {
    this.tinyInitiated.emit({status: 'initiated'});
    console.log(`this.tinyInitiated.emit({status: 'initiated'});`);
  }

  setFocus(switchB) {
    if ( switchB ) {
      this.focusSwitch = true;
      if ( this.attr && this.attr.editor === 'tiny' ) {
        console.log('this.chatMCE._editor', this.chatMCE, this.chatMCE._editor, this.chatMCE._editor.getBody());
        setTimeout( () => {
          this.chatMCE._editor.getBody().focus();
        }, 100);

      } else {
        try {
          const elem = this.textBlock.el.children[0].children[0];
          console.log('this.textarea', elem);
          const save =
          setTimeout( () => {
            elem.selectionStart = elem.value.length;
            elem.selectionEnd = elem.value.length;
            elem.focus();
          }, 100);
        } catch (err) {
          // -
        }
      }

    } else {
      this.focusSwitch = false;
      if ( !this.value ) {
        this.value = ' ';
      }
    }
  }

  inFocus(ev) {
    // Textarea styles are copied from node[.display]

    try{

      // look for element inside of this.resizeBox that has class .display
      // if found, copy styles to this.resizeBox
      let displayNode = this.inPreview.nativeElement.querySelector('.display');
      // inPreview
      if ( displayNode ) {
        const textarea = ev.target;

        // copy styles of font-size, font-family, font-weight, font-style, text-align, line-height from displayNode to textarea
        // evaluate styles in runtime
        const styles = window.getComputedStyle(displayNode);
        textarea.style.fontSize = styles.fontSize;
        textarea.style.fontFamily = styles.fontFamily;
        textarea.style.fontWeight = styles.fontWeight;
        textarea.style.fontStyle = styles.fontStyle;
        textarea.style.textAlign = styles.textAlign;
        textarea.style.lineHeight = styles.lineHeight;


        console.log('Eval parent and copy styles', { textarea, displayNode } );

      }

    } catch (err) {

    }

    setTimeout( () => {
      this.value = this.value + ' ';
      setTimeout( () => {
        this.value = this.value.substr(0, this.value.length-1);
      }, 1);
    }, 1);

  }

  ngOnInit() {
    // this.value = 'xxx';
    // this.attr['-webkit-line-clamp'] = 1;
    // console.log('ngOnInit XY WH', this.meta, this.meta.elements[this.metaIndex]);
    if ( this.meta ) {
      if ( this.meta.elements && this.meta.elements[this.metaIndex].x ) {
        this.usesXY = { x: this.meta.elements[this.metaIndex].x, y: this.meta.elements[this.metaIndex].y };
      }
      if ( this.meta.elements && this.meta.elements[this.metaIndex].w ) {
        this.usesWH = { w: this.meta.elements[this.metaIndex].w + 2, h: this.meta.elements[this.metaIndex].h + 2 };
      }
      if ( this.meta.elements && this.meta.elements[this.metaIndex].swap ) {
        this.usesSwapable = true;
      }

      // LOGO SWAP/DATA WORK
      if ( this.meta.elements[this.metaIndex].type === 'logo' && this.meta.elements[this.metaIndex].mode === 'image' ) {
        // this.meta.elements[this.metaIndex].data.value = this.meta['media_logo'];
      }
      if ( this.meta.elements[this.metaIndex].type === 'logo' && this.meta.elements[this.metaIndex].mode === 'text' ) {
        // this.meta.elements[this.metaIndex].swap.value = this.meta['media_logo'];
      }


      if (  ( this.meta.elements[this.metaIndex].type === 'logo' && this.meta.elements[this.metaIndex].mode === 'image' ) ||
            this.meta.elements[this.metaIndex].type === 'image' ||
            this.meta.elements[this.metaIndex].type === 'layer'

      ) {
        if ( this.meta.elements[this.metaIndex].data.show !== 0 && this.meta.elements[this.metaIndex].data.show !== 1 ) {
          this.meta.elements[this.metaIndex].data.show = 1;
        }
        if ( !this.meta.elements[this.metaIndex].data.fill ) {
          if ( this.meta.elements[this.metaIndex].type === 'layer' ) {
            this.meta.elements[this.metaIndex].data.fill = 'cover';
          } else {
            this.meta.elements[this.metaIndex].data.fill = 'contain';
          }
        }
        if ( !this.meta.elements[this.metaIndex].data.posx ) {
          this.meta.elements[this.metaIndex].data.posx = 50;
        }
        if ( !this.meta.elements[this.metaIndex].data.posy ) {
          this.meta.elements[this.metaIndex].data.posy = 50;
        }
      }
    }


    if ( !this.usesWH || this.type === 'layer' || this.type === 'root' /*|| !this.usesWH*/ ) {
      this.resizable = false;
    }

    if ( this.resizable ) {
      const el = this.host.nativeElement.lastElementChild;
      this.observer = new ResizeObserver(entries => {
         // handle resize
         this.updateResize(entries);
      });
      this.observer.observe(el);
    }
  }
  ngOnDestroy() {
    if ( this.resizable ) {
      this.observer.unobserve(this.host.nativeElement.lastElementChild);
    }
  }

  async copyContent() {
    function decodeEntities(s){
      var str, temp= document.createElement('p');
      temp.innerHTML= s;
      str= temp.textContent || temp.innerText;
      temp=null;
      return str;
    }
    console.log('copyContent');
    const toast = await this.toastController.create({
      message: 'Copied to clipboard',
      duration: 2000
    });
    toast.present();

    const write = decodeEntities(this.value);
    console.log(write);

    (window as any).navigator.clipboard.writeText(write).then().catch(e => console.error(e));
  }

  async downloadContent() {
    console.log('downloadContent');
    const toast = await this.toastController.create({
      message: 'Download requested',
      duration: 2000
    });
    // toast.present();

    const element = (window as any).document.createElement('a');
    element.setAttribute('href', (this.value));
    element.setAttribute('target', '_blank');
    element.setAttribute('download', this.value.split('/')[this.value.split('/').length - 1] + '.png');

    element.style.display = 'none';
    (window as any).document.body.appendChild(element);

    element.click();

    (window as any).document.body.removeChild(element);
  }

  dragConstrainPoint = (point: any, dragRef: any) => {
    const scaleElem = (window as any).document.getElementById('saveArea');
    // console.log({scaleElem});
    let scale = 1;
    if (scaleElem) {
      scale = parseFloat(scaleElem.dataset.scale);
      //scale = 0.6;
    }
    let zoomMoveXDifference = 0;
    let zoomMoveYDifference = 0;
    if (scale !== 1) {
      zoomMoveXDifference = (1 - scale) * dragRef.getFreeDragPosition().x;
      zoomMoveYDifference = (1 - scale) * dragRef.getFreeDragPosition().y;
    }

    return {
      x: point.x + zoomMoveXDifference as number,
      y: point.y + zoomMoveYDifference as number
    };
  };

  startDragging(event) {
    const scaleElem = (window as any).document.getElementById('saveArea');
    // console.log({scaleElem});
    let scale = 1;
    if (scaleElem) {
      scale = parseFloat(scaleElem.dataset.scale);
      //scale = 0.6;
    }
    console.log(this.usesXY);

    const position = {
      x: this.usesXY.x * scale,
      y: this.usesXY.y * scale
    };
    event.source._dragRef.setFreeDragPosition(position);
    (event.source._dragRef as any)._activeTransform = this.usesXY;
    (event.source._dragRef as any)._applyRootElementTransform(this.usesXY.x, this.usesXY.y);
  }

  updateDrag(event) {
    const scaleElem = (window as any).document.getElementById('saveArea');
    let scale = 1;
    if (scaleElem) {
      scale = parseFloat(scaleElem.dataset.scale);
      //scale = 0.6;
    }
    console.log('drop', event);
    console.log('scale', scale);
    let element = event.source.getRootElement();
    let boundingClientRect = element.getBoundingClientRect();
    let parentPosition = this.getPosition(element);
    console.log('x: ' + (boundingClientRect.x - parentPosition.left), 'y: ' + (boundingClientRect.y - parentPosition.top));

    console.log('real object xy', boundingClientRect.x, boundingClientRect.y);
    console.log('parent object lt', parentPosition.left, parentPosition.top);
    console.log('saveArea boundry lt',  scaleElem.getBoundingClientRect());

    this.meta.elements[this.metaIndex].x = (boundingClientRect.x - scaleElem.getBoundingClientRect().left)/scale;
    this.meta.elements[this.metaIndex].y = (boundingClientRect.y - scaleElem.getBoundingClientRect().top)/scale;

    this.meta.elements[this.metaIndex].x = Math.round(this.meta.elements[this.metaIndex].x);
    this.meta.elements[this.metaIndex].y = Math.round(this.meta.elements[this.metaIndex].y);

    if ( this.meta.elements[this.metaIndex].x === 0 ) {
      this.meta.elements[this.metaIndex].x = 1;
    }

    /*
    if ( this.injectedNav ) {
      this.meta.elements[this.metaIndex].x -= 380;
    }
    */

    console.log('final object', {
      x: this.meta.elements[this.metaIndex].x,
      y: this.meta.elements[this.metaIndex].y,
      data: this.meta.elements[this.metaIndex]
    });

    this.getPositionChange.emit({action: 'drop'});
  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }

  public actionWrapper() {
    if (!this.actionLock) {
      this.actionLock = true;
      this.action();
      // alert('x');
      setTimeout(() => {
        this.actionLock = false;
      }, 1000);
    }
  }

  public startResizeEvent(self) {
    this.resizing = true;
    this.saveAreaScaleValue = (window as any).document.getElementById("saveArea").getAttribute('data-scale');
    this.saveAreaScaleValue = parseFloat(this.saveAreaScaleValue);
    (window as any).document.addEventListener('mousemove', this.onMouseMove);
    (window as any).document.addEventListener('mouseup', this.onMouseUp);
  }

  public updateResize(entries) {
    // console.log('updateResize, entry', entries);
    try {
      const wh = { w: entries[0].contentRect.width , h: entries[0].contentRect.height };
      // console.log(wh, this.meta.elements[this.metaIndex]);
      this.meta.elements[this.metaIndex].w = wh.w;
      this.meta.elements[this.metaIndex].h = wh.h;
    } catch (err) {
      // -
    }
  }
  public mouseUp() {
    if ( this.resizing === true ) {
      this.resizing = false;
      // this.getPositionChange.emit({action: 'resize'});
    }
    this.mousedown = false;
  }
  public mouseLeave(ev, resizeBox) {
    if ( this.resizing === true ) {
      // console.log('mouse left even if its still points down !', ev, {resizeBox});
      // console.log('ev x', ev.x, 'box x', resizeBox.getBoundingClientRect().x);
      // resizeBox.style.width = (ev.x - resizeBox.getBoundingClientRect().x) + 'px';
      // this.mouseUp();
      this.mouseUp();
    }
  }
  public mouseDown(ev) {
    if ( this.resizing === true ) {
      console.log('mouseDown', ev);
      this.mousedown = true;
    }
  }

  async deleteElement() {
    const alert = await this.alertController.create({
   cssClass: 'application-v2 alert-v2',
   header: 'Confirm!',
   message: 'Are you sure you want to remove this element ? You will not be able to add this back once removed.',
   buttons: [
     {
       text: 'Cancel',
       role: 'cancel',
       // cssClass: 'secondary',
       // id: 'cancel-button',
       handler: (blah) => {
         console.log('Confirm Cancel: blah');
       }
     }, {
       text: 'Delete',
       // id: 'confirm-button',
       cssClass: 'danger',
       handler: () => {
         console.log('Confirm Okay');
         delete this.meta.elements[this.metaIndex];
         this.meta.elements.splice(this.metaIndex, 1);
         console.log('Confirm Okay', this.meta.elements);
         this.getPositionChange.emit({action: 'delete'});
       }
     }
   ]
 });

 await alert.present();
  }

  changeNonEmpty(ev) {
    console.log('ev', ev, this.value, this.value.length);
    if ( !this.value.length ) {
      clearTimeout(this.debounce);
      this.debounceValue = 'YourLogo';
      this.value = 'YourLogo';
    } else {
      this.change(ev);
    }
  }

  change(ev) {
    // this.value = 'xxx';
    if ( this.key ) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout( () => {
        if ( this.debounceValue !== this.value ) {
          console.log('change', ev, this.key, this.value, typeof this.value, this.value.length);
          this.getValueChange.emit({key: this.key, value: this.value});
          this.debounceValue = this.value;
        }
      }, 1000); // debounce
    }
  }

  blure(ev) {
    this.viewEdit = false;
  }

  enterViewEdit() {
    this.viewEdit = true;
    setTimeout( () => {
      this.chatMCE._element.focus();
      // console.log('chatMCE', );
    });
  }

  joinFont(subj) {
    if ( subj ) {
      return subj.replace(/\s/g, '');
    }

  }

  ignoreInvalidAttrC(attr, meta = {}, extra = {}, ignore = []) {
    const r = this.ignoreInvalidAttr(attr, meta, extra, ignore);
    console.log('ignoreInvalidAttrC, r', r);
    return r;
  }

  ignoreInvalidAttr(attr, meta = {}, extra = {}, ignore = []) {
    const copy = JSON.parse(JSON.stringify(attr));
    // console.log('ignoreInvalidAttr', copy);
    delete copy.font;
    const clamp = Object.keys(copy).find( (key) => key === 'line-clamp' );
    if ( clamp ) {
      copy['-webkit-line-clamp'] = copy['line-clamp'];
    }
    if ( ignore.length > 0 ) {
      ignore.forEach( subjectKey => {
        const find = Object.keys(copy).find( (key) => key === subjectKey );
        if ( find ) {
          delete copy[subjectKey];
        }
      });
    }
    // if ( copy.themecolor ) {
      // const themeColorOverride = copy.themecolor;
      // copy.color = meta[themeColorOverride];
      // console.log('themecolor', copy);
      // delete copy.themecolor;
    // }
    const ret = {
      ...meta,
      ...copy,
      ...extra
    };
    // console.log('ignoreInvalidAttr', ret);
    return ret;
  }

  getFilter(hex) {
    if ( hex === '#7c8b9a' ) {

    } else {
      if (this.rtCahche !== hex && hex) {
        const filt = this.hf.hexToFilter(hex);
        const rt = {filter: filt.filter.substr(8, filt.filter.length - 9 )};
        this.rtCahcheFilter = rt;
        if ( filt.loss < 5 ) {
          this.rtCahche = hex;
        }
        // console.log('calc filter', filt);
        return this.rtCahcheFilter;
      } else {
        return this.rtCahcheFilter;
      }
    }


    // console.log('rt', rt);
    // return this.rtCahcheFilter;
  }

  modeElement() {
    // -
    if ( this.meta.elements[this.metaIndex].mode === 'text' ) {
      this.meta.elements[this.metaIndex].mode = 'image';
    } else {
      this.meta.elements[this.metaIndex].mode = 'text';
    }

    if ( this.meta.elements[this.metaIndex].swap ) {
      const swap = JSON.parse(JSON.stringify(this.meta.elements[this.metaIndex].data));
      this.meta.elements[this.metaIndex].data = this.meta.elements[this.metaIndex].swap;
      this.meta.elements[this.metaIndex].swap = swap;
    } else {
      const swap = JSON.parse(JSON.stringify(this.meta.elements[this.metaIndex].data));
      this.meta.elements[this.metaIndex].swap = swap;
      this.meta.elements[this.metaIndex].data = { value: 'YourLogo', size: 32, line: 48 };
    }

    console.log('this.meta.elements[this.metaIndex]', this.meta.elements[this.metaIndex]);
  }

  switchDepthMode(ev) {
    if ( this.meta && this.meta.elements[this.metaIndex].depth ) {
      /*
      if ( this.meta && this.meta.elements[this.metaIndex].depth === 'auto' ) {
        this.meta.elements[this.metaIndex].depth = 101;
        return;
      }
      */
      if ( this.meta && this.meta.elements[this.metaIndex].depth === 101 ) {
        this.meta.elements[this.metaIndex].depth = -99;
        return;
      }
      if ( this.meta && this.meta.elements[this.metaIndex].depth === -99 ) {
        this.meta.elements[this.metaIndex].depth = 101;
        return;
      }
    } else {
      this.meta.elements[this.metaIndex].depth = 101;
    }
  }

  @Input() brainstormingContext = {};

  brainstormAi(context) {
    console.log('setupText', this.brainstormingContext);
    let setupText = this.value;
    // strip all html from text
    setupText = setupText.replace(/<[^>]*>?/gm, '');
    //&nbsp; to ' '
    setupText = setupText.replace(/&nbsp;/gm, ' ');

    this.brainstorming.setup(setupText, { ...this.brainstormingContext }, 'edit');
    // this.brainstorming.showUserInterface();
    // this.brainstorming.setState(0);
  }

}
