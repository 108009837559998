
import { Component, OnInit, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-custom-dropdown-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class CustomDropdownPopoverComponent implements OnInit {

  @Input() dropdownOptionsOriginal: any;
  @Input() dropdownOptions: any;
  @Input() value: any;

  constructor(
    public popoverController: PopoverController
  ) { }

  checkAgainstValues(option) {
    let vals = [];
    
    try{
      // console.log('checkAgainstValues', { val: this.value });
      const value = this.value;
      if ( Array.isArray(value) ) {
        vals = value;
      } else if ( value ) {
        vals.push(value);
      }
      vals.forEach(val => {
        // console.log('checkAgainstValues', { vals, val, option });
        if ( option.value === val ) {
          this.selectItem({}, option);
        }
      });
    } catch (e) {
      // console.log('checkAgainstValues error', e);
    }
  }

  updateSelectedItems(optionc) {
    // update output values
    // console.log('this.dropdownOptions', this.dropdownOptions);
    let res: any = [];
    if ( this.dropdownOptions.multiple ) {
      this.dropdownOptions.options.forEach(option => {
        if ( option.checked ) {
          res.push(option.value);
          console.log('updateSelectedItems', res);
        } else if ( option.group && option.group.options && option.group.options.length > 0) {
          option.group.options.forEach(groupOption => {
            if( groupOption.checked ) {
              res.push(groupOption.value);
            }
          });

        }
      });
      // filter res from duplicates
      res = res.filter((v, i, a) => a.indexOf(v) === i);
      // this.value = res;
    } else {
      // this.value = optionc.value;
      res = optionc.value;
    }
    this.dropdownOptionsOriginal.change = res;
    console.log('this.dropdownOptionsOriginal.change', res);
  }

  ngOnInit() {

    this.dropdownOptions = JSON.parse(JSON.stringify(this.dropdownOptionsOriginal));
    // console.log('checkAgainstValues value', this.dropdownOptions, this.value);

    this.dropdownOptions.options.forEach(option => {
      // console.log('checkAgainstValues init', option);
      if ( option.value ) {
        this.checkAgainstValues(option);
      } else if ( option.group && option.group.options && option.group.options.length > 0) {
        option.group.options.forEach(groupOption => {
          this.checkAgainstValues(groupOption);
        });
      }
    });

  }

  getSearchResults() {
    if ( this.dropdownOptions ) {
      return this.dropdownOptions.options;
    }
  }

  searchDebounce: any;

  searchForItems(event) {
    console.log('searchForItems', event);
    // debounce via setTimeout 500 ms
    // execute search via filteredResults as it containse filering logic
    // this.dropdownOptions.searchQuery = event;
    try{
      clearTimeout(this.searchDebounce);
    } catch (e) {
      // console.log('searchForItems error', e);
    }
    this.dropdownOptions.searchLoading = true;
    this.searchDebounce = setTimeout(() => {
      this.dropdownOptions.searchLoading = false;
      this.filteredResults(true);
    }, 500);

  }

  private filteredResultsCache;
  /* ATTENTION/TODO: Needs To be hardley optimized in future */
  filteredResults(retake = false) {

    if ( retake === false && this.filteredResultsCache ) {
      return this.filteredResultsCache;
    }
    if ( !this.dropdownOptions.options ) {
      return [];
    }

    // Search
    if ( this.dropdownOptions.searchQuery && this.dropdownOptions.searchQuery.length > 0) {

      const search = this.dropdownOptions.searchQuery;
      const searchBySubject = (subject, option, search) => {
        
        console.log('search in dropdown debug', { search, subject, option });
        if ( search && search.length > 1 ) {
          try{
            const searchRegexp = new RegExp(search, 'gi');
            if ( subject.match(searchRegexp) ) {
              console.log('compareAgainst debug exists', { search, subject });
              return option;
            }
          } catch (err) {}
        } else if( search && search.length > 0 ) {
          if ( subject === search ) {
            return option;
          }
        }
        return null;
  
      };

      let resultArray = [];
      if ( this.dropdownOptions.options && this.dropdownOptions.options.length > 0 ) {
  
        this.dropdownOptions.options.forEach(option => {
  
          if ( option.group && option.group.options && option.group.options.length > 0 ) {
            // group
            for( let groupOption of option.group.options ) {
              const subject = groupOption[this.dropdownOptions.search];
              const groupFound = searchBySubject(subject, groupOption, search);
              if ( groupFound ) {
                resultArray.push(groupFound);
              }
            }
          } else {
            // individual
            const subject = option[this.dropdownOptions.search];
            const found = searchBySubject(subject, option, search);
            if ( found ) {
              resultArray.push(found);
            }
          }
    
        });
  
      }

      this.filteredResultsCache = [{ 
        group: { 
          label: 'Results', 
          icon: 'search', 
          options: resultArray 
        }
      }];

      return this.filteredResultsCache;

    } else if ( this.dropdownOptions.options ) {
      // Default
      this.filteredResultsCache = this.dropdownOptions.options;
    } else {
      // Empty
      this.filteredResultsCache = [];
    }

    return this.filteredResultsCache;
  }

  selectItem(e, option) {
    console.log('selectItem', e, option);
    if ( !this.dropdownOptions.multiple ) {

      this.dropdownOptions.options.forEach(option => {
        if ( option.value ) {
          option.checked = false;
        } else if ( option.group && option.group.options && option.group.options.length > 0) {
          option.group.options.forEach(groupOption => {
            groupOption.checked = false;
          });

        }
      });

      if ( e && e.isTrusted ) {
        setTimeout(() => {
          this.popoverController.dismiss();
        }, 150);
      }

    }

    option.checked = !option.checked;
    this.updateSelectedItems(option);
  }

}
